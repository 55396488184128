import React from 'react';
import '../Collabs.css'
import twitterIcon from '../../../assets/twitter.png'


export default function CollabCard({Pic, Artist, Website, Twitter}){

    return(
        <>
            <div className="collabCard mx-auto text-center">
                <img src={Pic} className="collabImg img-fluid"  alt=""/>
                <div className="collabFlex">
                    <p className="collabName">{Artist}</p>
                    {Twitter===""?
                        <></>:
                        <a href={Twitter} target={'_blank'} rel="noreferrer" ><img src={twitterIcon} className="collabTwitter" alt=""/></a>
                    }
                </div>
                {Website===""?
                    <></>:
                    <a className="collabObjkt" href={Website} target={'_blank'} rel="noreferrer" >Go to Website</a>
                }
            </div>
        </>
    );
}
