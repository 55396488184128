import React from "react";
import Faq from "react-faq-component";

import '../Home.css'

const data = {
    title: "",
    rows: [
        {
            title: "What are A09's?",
            content: `A09’s are hand drawn digital art collectibles, embellished and assembled one by one with over 400 unique traits. Each A09 is inspired by the fantastic people who build and make up the NFT community, from influencers, to illustrators, to musicians.`,
        },
        {
            title: "How do you ensure a fair distribution of these A09's?",
            content:
            `The contract contains a provenance hash (An IPFS-hash that points to a document containing
            a mapping from token ids to a fingerprint of the art). This allows anyone to verify that we
            didn’t assign A09 after minting them. Furthermore, at the start of the sale, the harbinger
            price oracle is used to randomize the starting id of the sale`,
        },
        {
            title: "What happens after I mint?",
            content: `After you mint your A09, the tokens will be visible in your wallet and in your objkt.com collection page. 
            Initially you will only see the base A09 and all traits will be hidden. It’s only after the sale ends, 
            that the actual A09 (with all its traits) will be revealed to you.`,
        },
        {
            title: "How many A09's can be minted per wallet?",
            content: <p>To ensure bots dont take away all the A09, we have limited the amount of A09 that can be minted at one time.</p>,
        },
        {
            title: "Where can the A09's be traded?",
            content: <p>The A09 FA2 tokens will have their own collection on objkt.com and can be traded there.</p>,
        },
    ],
};

const styles = {
    bgColor: 'transparent',
    titleTextColor: "#FCEDF9",
    rowTitleColor: "#FCEDF9",
    rowContentColor: '#ccc',
    arrowColor: "#FCEDF9",
    rowContentPaddingTop: '10px',
    rowContentPaddingBottom: '10px',
    rowContentTextSize: '20px'
};

const config = {
    animate: true,  
    // arrowIcon: "V",
    tabFocus: true
};

export default function FAQ() {

    return (
        <div>
            <Faq
                data={data}
                styles={styles}
                config={config}
            />
        </div>
    );
}