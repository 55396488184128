import React from "react";
import Copy from '../../../assets/copy.png'
import {CopyToClipboard} from 'react-copy-to-clipboard';

export default function Provenance({title, address}){

    return(
        <>
            <div className="provenance">
                <p className="provenanceTitle">{title}</p>
                <div className="flex provenanceContent">
                    {title === "Provenance Hash" ?
                        <a href={"https://cloudflare-ipfs.com/ipfs/" + address} className="addressLink" target={"_blank"} rel="noreferrer"><p className="provenanceAddress">{address}</p></a>:
                        <a href={`https://better-call.dev/mainnet/${address}/operations`} className="addressLink" target={"_blank"} rel="noreferrer"><p className="provenanceAddress">{address}</p></a>
                    }
                    <CopyToClipboard text={address}>
                        <img src={Copy} className="copyIcon" alt=""/>
                    </CopyToClipboard>
                </div>
            </div>
        </>
    )
}
