import { BeaconWallet } from '@taquito/beacon-wallet';
import { TezosToolkit } from '@taquito/taquito';
import { MichelCodecPacker } from "@taquito/taquito";


const DAPP_NAME = "Atelier 09"
// const RPC_URL = "https://hangzhounet.smartpy.io";
const RPC_URL = "https://mainnet.api.tez.ie";
// const NETWORK = "hangzhounet";
const NETWORK = "mainnet";
const CONTRACT_ADDRESS = "KT1C3SDp3rWzzA8GBMLJJ7CdEgTQa4g7WbTB"

const Tezos = new TezosToolkit(RPC_URL);
Tezos.setPackerProvider(new MichelCodecPacker());

const wallet = new BeaconWallet({
    name: DAPP_NAME,
    preferredNetwork: NETWORK,
    colorMode: 'light'
});

// Setting the wallet as the wallet provider for Taquito.
Tezos.setWalletProvider(wallet)

const network = {
    type: NETWORK,
};

const getActiveAccount = async () => {
  return await wallet.client.getActiveAccount();
};

const syncWallet = async () => {
  const activeAccount = await getActiveAccount();
  // no active account, we need permissions first
  if (!activeAccount) {
    await wallet.requestPermissions({ network });
  }
  return await getActiveAccount();
}

const unsyncWallet = async () => {
  return wallet.client.clearActiveAccount();
}

const getContract = async () => {
  return Tezos.wallet.at(CONTRACT_ADDRESS);
}

const getContractStorage = async () => {
  return (await getContract()).storage();
}

export {
    Tezos,
    wallet,
    getActiveAccount,
    syncWallet,
    unsyncWallet,
    getContract,
    getContractStorage
};
