import React, { useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import './Collabs.css'
import json from './Collabs.json'
import CollabCard from './components/CollabCard.js'


export default function Collabs(){

    const [jsonData, setJsonData] = useState(json)

    function searchHandler(e){
        const query = e.target.value.toUpperCase()
        setJsonData(json.filter((data) => data.Artist.toUpperCase().indexOf(query) !== -1))
    }

    return(
        <Container fluid>
            <Row className="CollabSearch">
                <Col xs={12} md={8} lg={10} className="mx-auto">
                    <div className="searchDiv text-center">
                        <input type="text" onChange={searchHandler} className="searchBar" placeholder="Seach by Artist Name"/>
                    </div>
                </Col>
            </Row>
            <Row className="collabMain">
                <Col className="">
                    <Row>
                        {jsonData.map((data, i) =>{
                            return <Col xs={12} sm={6} md={4} xl={3} key={i} className="">
                                <CollabCard data-key={i}
                                    Pic = {data.Pic}
                                    Artist = {data.Artist}
                                    Website = {data.Website}
                                    Twitter = {data.Twitter}
                                />
                            </Col>
                        })}
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}
