import React, { useEffect, useState } from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './Home.css'
import Gif from '../../assets/Gif 2.gif'
import Webp from '../../assets/hero2.webp'
import Twitter from '../../assets/twitter.png'
import SecondaryImg from '../../assets/sec2Image.png'
import AboutPic from '../../assets/vamp.png'
import up from '../../assets/up.png'

import Sky from '../../assets/sky.jpg'
import Loona from '../../assets/looona.jpg'
import Sonia from '../../assets/soniu.jpg'
import Shwetal from '../../assets/shwetal.jpg'

import Timer from "./components/Timer.js"
import FAQ from "./components/Faq.js"
import ProgressBar from "@ramonak/react-progress-bar";
import Provenance from "./components/Provenance.js"

import {getContract, getActiveAccount} from '../../tezos';

import {comingSoon, presale, publicsale, endsale} from '../../config.js';



export default function Home(){

    const [totalMints, setTotalMints] = useState(0);
    const [add, setAdd] = useState(1);
    const [resultTime, setResultTime] = useState(<></>);
    const [contract, setContract] = useState(undefined);
    const [storage, setStorage] = useState(undefined);
    const [mints, setMints] = useState({});


    useEffect(() => {
        (async () => {
            const contract_ = await getContract();
            const storage_ = await contract_.storage();
            console.log(storage_);
            setContract(contract_);
            setStorage(storage_);
            fetchUserData(storage_);
        })();
    }, [])

    useEffect(() => {
        if (storage) {
            setTotalMints(storage.total_mints.toNumber());
        }
    }, [storage]);

    useEffect(() => {
        const interval = setInterval(async () => {
            if (contract) {
                const storage_ = await contract.storage();
                setStorage(storage_);
            }
        }, 60000)
        return () => {
            clearInterval(interval);
        }
    }, [contract])

    useEffect(() => {
        const interval = setInterval(() => {
            function time(){
                if (comingSoon){
                    return <>
                        <p className="timerHead">Going live on 19th February!</p>
                    </>
                }
                else if (new Date() < presale) {
                    return <>
                        <p className="timerHead">Presale starts in</p>
                        <Timer time={presale} />
                    </>
                }
                else if (new Date() < publicsale) {
                    return <>
                        <p className='strokeTimer'>PRESALE IS LIVE</p>
                        <p className="timerHead">Public Sale starts in</p>
                        <Timer time={publicsale} />
                    </>
                }
                else if (new Date() < endsale) {
                    return <>
                        <p className="strokeTimer">PUBLIC SALE IS LIVE!</p>
                    </>
                }
                else if (new Date() > endsale) {
                    return <>
                        <p className="strokeTimer">SOLD OUT</p>
                    </>
                }   
            }
            setResultTime(time());
        }, 1000);
        return () => {
            clearInterval(interval);
        }
    }, []);


    async function mint(params){
        const toastId = "mint";
        toast.loading(
            "Sending request to your wallet. Please confirm the transaction.",
            {toastId: toastId},
        )
        try {
            var contract_;
            if (contract) {
                contract_ = contract;
            } else {
                contract_ = await getContract();
            }
            var op;
            if (new Date() > presale & new Date() < publicsale){
                op = await contract_.methods.mint(params).send({amount: params * 16690000, mutez: true});
            }
            else {
                op = await contract_.methods.mint(params).send({amount: params * 19990000, mutez: true});
            }
            toast.update(toastId, {
                render: "Minting A09 for you...",
            })
            const res = await op.confirmation();
            const status = await op.status();
            console.log(op, res, status);
            if (res.completed && status === "applied") {
                toast.update(toastId, {
                    render: "A09 minted!! 🎉",
                    type: toast.TYPE.SUCCESS,
                    onClick: () => window.open("https://tzkt.io/"+op.opHash, "_blank"),
                    isLoading: false,
                    autoClose: 5000,
                    closeOnClick: true,
                })
            } else {
                toast.update(toastId, {
                    render: "Something went wrong. Click here to know more.",
                    type: toast.TYPE.ERROR,
                    onClick: () => window.open("https://tzkt.io/"+op.opHash, "_blank"),
                    isLoading: false,
                    autoClose: 5000,
                    closeOnClick: true,
                })
            }
        } catch (error) {
            toast.update(toastId, {
                render: error.message,
                type: toast.TYPE.ERROR,
                isLoading: false,
                autoClose: 5000,
                closeOnClick: true,
            })
        }
        const storage_ = await contract_.storage();
        setStorage(storage_);
        fetchUserData(storage_);
    }

    async function fetchUserData(storage) {
        const address = (await getActiveAccount())?.address;
        console.log(address);
        if (address) {
            if (new Date() > presale & new Date() < publicsale) {
                const userData = await storage.presale_whitelist.get(address)
                console.log("Presale active")
                console.log(userData);
                setMints({
                    whitelisted: (userData !== undefined),
                    presaleLimit: userData?.limit?.toNumber(),
                    presaleMinted: userData?.minted?.toNumber()
                })
            }else {
                const userData = await storage.mints.get(address)
                console.log("Public sale active")
                console.log(userData);
                setMints({
                    publicSaleMinted: userData?.minted?.toNumber(),
                    publicSaleLimit: storage?.max_mint.toNumber()
                })
            }
        }
    }

    function addMint(){
        var limit = storage?.mint_limit?.toNumber() || 33;
        let num = add + 1;
        if (num > limit){
            setAdd(limit)
        }else{
            setAdd(num);
        }
    }

    function subMint(){
        let num = 0;
        if (add > 1){
            num = add - 1;
        }else{
            num = add;
        }
        setAdd(num);
    }

    return(
        <div className="main">
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                closeOnClick
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
            <Row className="heroSection">
                <Col md={5} className="order-12 order-md-1">
                    <h2 className="head2 font-face">Welcome to the</h2>
                    <h1 className="head1 font-face">ATELIER 09</h1>
                    <p className='heroText'>
                        Atelier 09 is a <a href='#section3' className='hyperlink'> female lead </a> art project living on the Tezos blockchain created in 2021.
                        All A09’s are <span className='hyperlink'> hand drawn </span> digital art collectibles, embellished and assembled one by one 
                        with over <span className='hyperlink'> 400 unique traits </span> including 1/1 artworks by over <a href="/collabs" className='hyperlink'> 30+ collab artists </a>. The collection
                        is influenced by anime, manga and inspired by the fantastic people who make up the NFT
                        community, from various creatives to builders and collectors.
                    </p>
                    <div>{resultTime}</div>
               </Col>
                <Col md={1} xl={2} className="d-none d-md-block order-2">
                </Col>
                <Col md={6} lg={6} xl={5} className="order-1 order-md-12">
                    <picture>
                        <source srcSet={Webp} type="image/webp" />
                        <source srcSet={Gif} type="image/jpeg" />
                        <Image fluid src={Gif} className="gif" />
                    </picture>
                </Col>
            </Row>

            {new Date() > presale?
            <div className="section2">
                <h2 className="sectionHead font-face">Mint A09's</h2>
                <Row className="section2Row">
                    <Col md={4}>
                        <Image fluid src={SecondaryImg} className="section2Img" alt=""/>
                    </Col>
                    <Col md={8}>
                        <div className='actionSec'>
                            <div className="beforeEnd">
                                <p className="sec2Head ">{totalMints} out of 3999 A09's have been minted</p>
                                <ProgressBar 
                                    className="progressBar"
                                    completed={totalMints}
                                    borderRadius="10px"
                                    maxCompleted={3999}
                                    bgColor="#EE54CF"
                                    baseBgColor="#373035"
                                    height="20px"
                                    width="80%"
                                    labelAlignment="center"
                                    labelSize="20px"
                                    isLabelVisible={false}
                                />
                                {new Date() < publicsale?
                                    <p className="section2Text sec2Cost">Each A09 costs 16.69 XTZ</p>:<></>
                                }
                                {new Date() > publicsale & new Date() < endsale?
                                    <p className="section2Text sec2Cost">Each A09 costs 19.99 XTZ</p>:<></>
                                }
                                {new Date() < endsale?
                                    <>
                                        <div className='mint'>
                                            <div className='mintBtnDiv'>
                                                <div className='inputDiv'>
                                                    <button className='upBtn' onClick={addMint}><Image className='upIcon' src={up} alt="." /></button>
                                                    <button className='downBtn' onClick={subMint}><Image className='downIcon' src={up} alt="." /></button>
                                                </div>
                                                <input className='mintInput' type='text' value={add} readOnly={true} />
                                            </div>
                                            <button onClick={() => mint(add)} type='submit' className='mintSubmit'>Mint</button>
                                        </div>
                                        {add === 33?
                                            <p className='limitReached'>You have reached maximum one time mint limit</p>:
                                            <></>
                                        }
                                        <div className='userData'>
                                            {new Date() < publicsale?
                                                <>
                                                    {mints.whitelisted !== undefined?
                                                    <>
                                                        {mints.whitelisted?
                                                            <p>You have {mints.presaleLimit} spots. {mints.presaleMinted} used.</p>:
                                                            <p>Sorry! You are not A-listed</p>
                                                        }
                                                    </>:<></>}
                                                </>:
                                                <>
                                                    {mints.publicSaleMinted === undefined?
                                                        <p>Get a Waifu for your Laifu 😉</p>:
                                                        <p>{mints.publicSaleMinted} A09s minted. You can mint {mints.publicSaleLimit - mints.publicSaleMinted} more.</p>
                                                    }
                                                </>
                                            }
                                            
                                        </div>
                                    </>:<></>
                                }
                            </div>
                            {new Date() > endsale?
                                <div className="afterEnd">
                                    <p className="sectionObjText">You can buy secondary sales here</p>
                                    <div className='secondaryFlex'>
                                        <a className="objkt" href="https://objkt.com/collection/atelier09" target="_blank" rel="noreferrer">OBJKT</a>
                                        <a className="objkt rarible" href="https://rarible.com/collection/tezos/KT1C1izZNFFw5rYTr8cnCQJy1YRsrHjhobRJ" target="_blank" rel="noreferrer">RARIBLE</a>
                                    </div>
                                </div>:
                            <></>}
                        </div>
                    </Col>
                </Row>
            </div>:<></>
            }

            <div className='section2a'>
                <Row>
                    <Col md={6}>
                        <h2 className="sectionHead font-face">About Us</h2>
                        <p className='heroText'>
                            A09 features <span className="hyperlink"> 3999 hand drawn </span> digital art collectibles, embellished  one by one with over 
                            <span className="hyperlink"> 400 traits </span> and varying rarities, each created by the core members of the Atelier team.
                            With this method we were not limited to certain colour palettes, resulting in <span className="hyperlink">unlimited colour schemes</span>, great aesthetics and
                            each NFT being a unique piece of art.
                        </p>
                        <p className='heroText'>
                            With this passion project we wanted to incorporate the mentality and power of collaboration. Therefore,
                            we teamed up with <a className="hyperlink" href="/collabs">30+ collab artists</a>, each of which 
                            created 1/1 artworks which will be included in the initial drop, giving every collector the same chance
                            to obtain one of these legendary art pieces! Our focus lies in supporting and working with all kind of
                            creatives in the space, as well as giving back to the community that has supported us on this journey.
                        </p>
                    </Col>
                    <Col md={6} className='text-center'>
                        <Image fluid src={AboutPic} className="aboutPic" />
                    </Col>
                </Row>
                
            </div>

            <div className="section3 text-center" id="section3">
                <h2 className="sectionHead text-center font-face">Atelier09 Team</h2>
                <p className="sectionText text-center">Let’s build something beautiful together</p>
                <Row className="section3Row">
                    <Col md={3} sm={6} xs={6} className='profile'>
                        <Image src={Sky} className="profilePic" alt=""/>
                        <p className="profileName">Eselci</p>
                        <p className="profileType">Artist</p>
                        <div className="twitter mx-auto">
                            <a href="https://twitter.com/Eselcixo" target="_blank" rel="noreferrer"><img src={Twitter} className="twitterIcon" alt=""/></a>
                        </div>
                    </Col>
                    <Col md={3} sm={6} xs={6} className='profile'>
                        <Image src={Loona} className="profilePic" alt=""/>
                        <p className="profileName">Looona Lou</p>
                        <p className="profileType">Artist</p>
                        <div className="twitter mx-auto">
                            <a href="https://twitter.com/looonalou" target={'_blank'} rel="noreferrer"><img src={Twitter} className="twitterIcon" alt=""/></a>
                        </div>
                    </Col>
                    <Col md={3} sm={6} xs={6} className='profile'>
                        <Image src={Sonia} className="profilePic" alt=""/>
                        <p className="profileName">Soniu Peng</p>
                        <p className="profileType">Artist</p>
                        <div className="twitter mx-auto">
                            <a href="https://twitter.com/soniupeng" target={'_blank'} rel="noreferrer"><img src={Twitter} className="twitterIcon" alt=""/></a>
                        </div>
                    </Col>
                    <Col md={3} sm={6} xs={6} className='profile'>
                        <Image src={Shwetal} className="profilePic" alt=""/>
                        <p className="profileName">Shwetal</p>
                        <p className="profileType">Developer</p>
                        <div className="twitter mx-auto">
                            <a href="https://twitter.com/shwetalsoni19" target={'_blank'} rel="noreferrer"><img src={Twitter} className="twitterIcon" alt=""/></a>
                        </div>
                    </Col>
                </Row>
            </div>

            <div className="section4 text-center">
                <h2 className="sectionHead text-center faqHead font-face">FAQ</h2>
                <FAQ />
            </div>

            <div className="section5" id="section5">
                <h2 className="sectionHead text-center provenanceHead font-face">Provenance</h2>
                <Row className="section5Row">
                    <Col xl={6} lg={12} md={12}><Provenance title="Provenance Hash" address="QmUbJQdyftQuxa7YtgvNpKuW6WS2dCUxuCW4i4NuNcH3hF"/></Col>
                    <Col xl={6} lg={12} md={12}><Provenance title="Crowdsale Contract" address="KT1C3SDp3rWzzA8GBMLJJ7CdEgTQa4g7WbTB"/></Col>
                </Row>
            </div>
        </div>
    );
}
