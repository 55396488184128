import { BrowserRouter, Route } from 'react-router-dom';
import {
  Home,
  Collabs,
  Roadmap
} from './Pages';

import NavbarComponent from './components/Navbar.js';
import Footer from './components/Footer.js';


function App() {
  return (
    <BrowserRouter>
      <NavbarComponent />
      <Route exact path='/' component={Home}></Route>
      <Route exact path='/collabs' component={Collabs}></Route>
      <Route exact path='/roadmap' component={Roadmap}></Route>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
