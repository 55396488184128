import React, { useEffect } from "react";

export default function Timer({ time }){

    const [days, setDays] = React.useState(0);
    const [hours, setHours] = React.useState(0);
    const [minutes, setMinutes] = React.useState(0);
    const [seconds, setSeconds] = React.useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            const now = new Date()
            const timeDiff = Math.floor((time - now) / 1000);
            setDays(Math.floor(timeDiff / (60 * 60 * 24)));
            setHours(Math.floor(timeDiff / (60 * 60) % 24));
            setMinutes(Math.floor(timeDiff / 60 % 60));
            setSeconds(Math.floor(timeDiff % 60));
        }, 1000);
        return () => {
            clearInterval(interval);
        }
    }, [time]);
    

    return(
        <>
            <div className="timer text-center">
                <div className="timerDiv days">{days}</div>
                <div className="timerLabel">Days</div>
            </div>
            <div className="timer">
                <div className="timerDiv hour">{hours}</div>
                <div className="timerLabel">Hours</div>
            </div>
            <div className="timer">
                <div className="timerDiv min">{minutes}</div>
                <div className="timerLabel">Minutes</div>
            </div>
            <div className="timer">
                <div className="timerDiv sec">{seconds}</div>
                <div className="timerLabel">Seconds</div>
            </div>
        </>
    )
}