import React, { useState, useEffect } from 'react';
import { getActiveAccount, syncWallet, unsyncWallet } from "../tezos";
import '../static/css/navbar.css'
export default function SyncButton(){

    const [walletConnected, setWalletConnected] = useState(false);

    useEffect(() => {
        (async () => {
            const activeAccount = await getActiveAccount();
            if (activeAccount) {
                setWalletConnected(true);
                // console.log(activeAccount)
            }
        })()
    }, [])

    const handleLogin = async () => {
        if(!walletConnected) {
            let activeAccount = await getActiveAccount();
            if(!activeAccount) {
                activeAccount = await syncWallet();
            }
            setWalletConnected(true);
            // console.log(activeAccount);
        }else {
            await unsyncWallet();
            setWalletConnected(false);
        }
    }

    return(
        <>
            <button className="btn btnHollow" onClick={handleLogin}>
                {walletConnected ? ('Unsync') : ('Sync')}
            </button>
        </>
    );
}
