import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
// import { LinkContainer } from 'react-router-bootstrap';
import '../static/css/navbar.css'
import LoginButton from './LoginButton';
import Atelier from '../assets/logo-pink.png'



function NavbarComponent(props) {
	const location = useLocation()

	return (
		
		<Navbar collapseOnSelect expand="md" className="nav" variant="dark">
			<Container fluid>
			<Navbar.Brand as={Link} to='/'><img src={Atelier} className="atelierLogo" alt=""/></Navbar.Brand>
			<Navbar.Toggle aria-controls="responsive-navbar-nav" />
			<Navbar.Collapse id="responsive-navbar-nav">
				<Nav className="mx-auto">
					<Nav.Link as={Link} to='/collabs' className={"unselected " + (location.pathname === "/collabs" ? "selectedTab":"navColor")}>Collabs</Nav.Link>
					<Nav.Link as={Link} to='/roadmap' className={"unselected " + (location.pathname === "/roadmap" ? "selectedTab":"navColor")} >Roadmap</Nav.Link>
					<Nav.Link as={Link} to='#' className="objkt-nav" onClick={()=> window.open("https://gallery.atelier09.art/?sortBy=rarity", "_blank")}>Gallery</Nav.Link>
					<Nav.Link as={Link} to='#' className="objkt-nav" onClick={()=> window.open("https://objkt.com/collection/atelier09", "_blank")}>Objkt</Nav.Link>
					<Nav.Link as={Link} to='#' className="objkt-nav" onClick={()=> window.open("https://rarible.com/collection/tezos/KT1C1izZNFFw5rYTr8cnCQJy1YRsrHjhobRJ", "_blank")}>Rarible</Nav.Link>
				</Nav>
				<Nav>
					<LoginButton />
				</Nav>
			</Navbar.Collapse>
			</Container>
		</Navbar>
		
	);
}

export default NavbarComponent;