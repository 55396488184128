import React from 'react';
// import { Container, Row, Col } from 'react-bootstrap';
import './Roadmap.css'

import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import Charity from '../../assets/charity.png'
import Collab from '../../assets/collab.png'
import Airdrop from '../../assets/airdrop.png'
import Vote from '../../assets/vote.png'
import Winter from '../../assets/winter.png'

export default function Roadmap(){

    return(
        <div className="roadmap mx-auto">
            <h2 className="roadmapHead text-center font-face">Roadmap: Spring - Summer 2022</h2>
            <p className="roadmapText text-center">What we really want to do, how you can become part of it and what you will be rewarded with! </p>
            <VerticalTimeline>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: '#252425', color: '#fff'}}
                    contentArrowStyle={{ borderRight: '7px solid  #fff' }}
                    // date="2011 - present"
                    iconStyle={{ background: '#EE54CF', color: '#fff', verticalAlign: 'middle'}}
                    icon={<img src={Charity} className="charityIcon" alt=""/>}

                >
                    <h3 className="vertical-timeline-element-title">Charity</h3>
                    <p className="roadmapContent">
                        We will donate a percentage of the Atelier drop to a charity chosen by you, the A09 community!
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: '#252425', color: '#fff' }}
                    // date="2010 - 2011"
                    iconStyle={{ background: '#1E26CB', color: '#fff', verticalAlign: 'middle' }}
                    icon={<img src={Collab} className="collabIcon" alt=""/>}
                >
                    <h3 className="vertical-timeline-element-title">More Creative Collabs</h3>
                    <p className="roadmapContent">
                        To continue the theme of collaboration, we will be working with a variety of talented artists on future drops! Starting with monthly artist collaborations. 
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: '#252425', color: '#fff' }}
                    // date="2008 - 2010"
                    iconStyle={{ background: '#EE54CF', color: '#fff' }}
                    icon={<img src={Airdrop} className="collabIcon" alt=""/>}
                >
                    <h3 className="vertical-timeline-element-title">Airdrops</h3>
                    <p className="roadmapContent">
                        Atelier 09 NFTs serve as a ticket to claim free NFTs of future airdrops!
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: '#252425', color: '#fff' }}
                    // date="2006 - 2008"
                    iconStyle={{ background: '#1E26CB', color: '#fff' }}
                    icon={<img src={Vote} className="collabIcon" alt=""/>}
                >
                    <h3 className="vertical-timeline-element-title">Voting Rights</h3>
                    <p className="roadmapContent">
                    The A09 community will have several chances to play a major role in shaping the project's future Roadmap.
                    </p>
                </VerticalTimelineElement>
            </VerticalTimeline>
            <h2 className="roadmapHead2 text-center">Sneak Peeks: Fall - Winter 2022</h2>
            <VerticalTimeline>
                <VerticalTimelineElement
                    className="vertical-timeline-element--education"
                    contentStyle={{ background: '#252425', color: '#fff' }}
                    // date="April 2013"
                    iconStyle={{ background: '#EE54CF', color: '#fff'}}
                    icon={<img src={Winter} className="collabIcon" alt=""/>}
                >
                    <h3 className="vertical-timeline-element-title">COMING SOON</h3>
                    <p>
                        We will keep you updated on this site, on social media and Discord!
                        Your Atelier 09 Team <a className="roadmapTwitterLink" href="https://twitter.com/Atelier_09">#A09 #Atelier09</a> 
                    </p>
                </VerticalTimelineElement>
            </VerticalTimeline>
        </div>
    );
}
