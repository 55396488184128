import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../static/css/footer.css'
import Atelier from '../assets/footer-logo.png'
import Twitter from '../assets/twitter-footer.svg'
import Telegram from '../assets/telegram.svg'
import Discord from '../assets/discord.svg'

export default function Footer(){
    return(
        <>  <Container fluid>
            <Row className="footer">
                <Col>
                    <img src={Atelier} className="FooterAtelierLogo" alt="."/>
                </Col>
                <Col className='rightFooter'>
                    <div className='footerFlex'>
                        <a href="https://twitter.com/Atelier_09" target={'_blank'} rel="noreferrer">
                            <img src={Twitter} className='footerSocial twitterFooter' alt="twitter" />
                        </a>
                        <a href="https://discord.gg/WNPUtGHQGh" target={'_blank'} rel="noreferrer">
                            <img src={Discord} className='footerSocial discord' alt="discord" />
                        </a>
                        <a href="https://t.me/atelier09" target={'_blank'} rel="noreferrer">
                            <img src={Telegram} className='footerSocial telegram' alt="telegram" />
                        </a>
                    </div>
                </Col>
            </Row>
            </Container>
        </>
    )
}
