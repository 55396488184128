const comingSoon = false;

// const presale = new Date('2022-02-19T12:50:00Z');

// const publicsale = new Date('2022-02-19T20:10:00Z');

// const endsale = new Date('2022-02-20T18:00:00Z');

const presale = new Date('2022-02-19T18:30:00Z');

const publicsale = new Date('2022-02-21T18:30:00Z');

const endsale = new Date('2122-03-21T18:30:00Z');

export {
    comingSoon,
    presale,
    publicsale,
    endsale
}


